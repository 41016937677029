import React from 'react';
import './App.css';

const Right = ({formUrl}) => {
    return (
        <div className="right-section">
        <h3>설문조사 <a href={formUrl} target="_blank">새창에서 열기(클릭)</a></h3>
        <div className="right-survey">
          {formUrl ? (
            <iframe
              src={formUrl} // 전달받은 URL로 설정
              title="설문조사 폼"
              width="100%" // 컨테이너의 너비에 맞추기
              height="90%" // 적절한 높이 설정
              style={{ border: 'none' }} // 기본 테두리 제거
              allowFullScreen // 전체 화면 모드 허용
            ></iframe>
          ) : (
            <p>설문조사를 선택해주세요.</p> // URL이 없을 때 메시지 표시
          )}
        </div> 
        </div>
    );
};

export default Right;