// App.js
import React, { useState } from 'react';
import './App.css';
import Left from './Left';
import Right from './Right';
import pdf1 from './pdf/UIUX 국민평가단 사전교육 자료.pdf';
import pdf2 from './pdf/UIUX 국민평가단 활동지침서.pdf';

function App() {
  const [users, setUsers] = useState([]);
  const [selectedFormUrl, setSelectedFormUrl] = useState('');

  const fetchData = async (name, phone) => {
    try {
      const encodedName = encodeURIComponent(name);
      const encodedPhone = encodeURIComponent(phone);
      const response = await fetch(
        `/getSites?name=${encodedName}&phone=${encodedPhone}`
      );
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error('데이터를 가져오는 중 오류가 발생했습니다:', error);
    }
  };



  return (
    <div className="container">
      <Left
        users={users}
        fetchData={fetchData}
        setSelectedFormUrl={setSelectedFormUrl}
      />
      <Right formUrl={selectedFormUrl} />

      <div className="floating-buttons">
        <button className="faq-button" onClick={()=>window.open('/faq')}>
            FAQ
        </button>
        <button className="edu-button" onClick={() => window.open(pdf1)}>
            사전교육자료
        </button>
        <button className="edu2-button" onClick={() => window.open(pdf2)}>
            활동지침서
        </button>
      </div>

    </div>
  );
}

export default App;
