import React, { useEffect, useState } from 'react';
import './App.css';
import hang_logo from './img/hang_logo.jpg';
import nia_logo from './img/nia_logo.jpg';

const Left = ({ users, fetchData, setSelectedFormUrl }) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [filteredSites, setFilteredSites] = useState([]);
  const [isQueryButtonClicked, setIsQueryButtonClicked] = useState(false);
  const [surveyInProgress, setSurveyInProgress] = useState({});
  const [isQueryAttempted, setIsQueryAttempted] = useState(false); // 조회가 시도되었는지 확인
  const [isLoading, setIsLoading] = useState(false); // 로딩 상태 추가

  useEffect(() => {
    setFilteredSites(users);
    setIsLoading(false); // 데이터를 받은 후 로딩 상태를 false로 설정
  }, [users]); // users가 변경될 때마다 실행

  // 조회 버튼 클릭 핸들러
  const handleQuery = async () => {
    if (isQueryButtonClicked) {
      // 조회 버튼이 클릭된 상태에서 또 클릭하면 조회 시도
      if (name === '' && phone === '') {
        setFilteredSites([]);
        return;
      }
      
      // 로딩 상태 시작
      setIsLoading(true);
      await fetchData(name, phone);
    } else {
      // 처음 클릭 시
      if (name === '' && phone === '') {
        setFilteredSites([]);
        return;
      }

      setIsQueryAttempted(true); // 조회가 시도되었음을 표시
      setIsLoading(true); // 데이터 로드를 시작하므로 로딩 상태를 true로 설정
      await fetchData(name, phone);
      setIsQueryButtonClicked(true);
    }
  };

  // 키다운 이벤트 핸들러 (Enter 키 감지)
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleQuery(); // Enter 키를 누르면 handleQuery 호출
    }
  };

  // 로그아웃 핸들러
  const handleLogout = () => {
    setName('');
    setPhone('');
    setFilteredSites([]);
    setIsQueryButtonClicked(false);
    setIsQueryAttempted(false); // 로그아웃 시 조회 시도 상태 리셋
    setIsLoading(false); // 로그아웃 시 로딩 상태 리셋
  };

  // ******* 설문 버튼 클릭 핸들러
  const handleFormSelection = (formUrl, index) => {
    // 설문이 완료된 사이트는 무시
    if (filteredSites[index].complete === 1) {
      return;
    }
    setSelectedFormUrl(formUrl); // 부모 컴포넌트에 선택된 설문 URL을 전달
    // 클릭한 버튼만 "설문 중"으로 설정하고, 나머지는 초기화
    setSurveyInProgress((prev) => {
      const newSurveyState = {}; // 새로운 상태 객체 생성
      for (const key in prev) {
        newSurveyState[key] = false; // 모든 버튼의 상태를 false로 설정
      }
      newSurveyState[index] = true; // 클릭한 버튼만 "설문 중"으로 설정
      return newSurveyState; // 새로운 상태 반환
    });
  };

  // 완료 여부 토글 핸들러
  const handleCheckboxChange = async (siteId, currentCompleteStatus) => {
    const newCompleteStatus = currentCompleteStatus === 1 ? 0 : 1;

    try {
      // 서버 변경 시 여기 경로 수정!!!!
      const response = await fetch(`/api/assignment/${siteId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ complete: newCompleteStatus }),
      });

      if (!response.ok) {
        throw new Error('서버 오류');
      }

      setFilteredSites((prevSites) =>
        prevSites.map((site) =>
          site.key === siteId ? { ...site, complete: newCompleteStatus } : site
        )
      );
    } catch (error) {
      console.error('업데이트 중 오류 발생:', error);
    }
  };

  return (
    <div className="left-section">
      {/* 헤더 */}
      <div className="logo-container">
        <img src={hang_logo} alt="행정안전부 로고" />
        <img src={nia_logo} alt="한국지능정보사회진흥원 로고" />
      </div>
      <h2>공공부문 웹사이트 UI/UX 국민평가단</h2>
      {/* 유저정보 입력 영역 */}
      <div className='user'>
        <div className="input-user">
          <div className='input-line'>
            <label for="user-name">
              이&ensp;&ensp;&ensp;름
            </label>
            <input
              className="input-name"
              id="user-name"
              placeholder="이름 입력"
              value={name}
              onChange={(e) => setName(e.target.value)}
              onKeyDown={handleKeyDown} // Enter 키 감지 추가
            />
          </div>
          <div className='input-line'>
            <label className="phone-label" for="user-phone">
              전화번호
              <br/>
              <span className="sub-label">(4자리)</span>
            </label>
            <input
              className="input-phone"
              id="user-phone"
              placeholder="전화번호 입력"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              onKeyDown={handleKeyDown} // Enter 키 감지 추가
            />
          </div>
        </div>
        <div className='input-button'>
          <button className="query-button" onClick={handleQuery}>
            조회
          </button>
          {isQueryButtonClicked && (
            <button className="logout-button" onClick={handleLogout}>
              로그아웃
            </button>
          )}
        </div>
      </div>
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th>기관명</th>
              <th>사이트명</th>
              <th style={{width:'15%'}}>설문<br/>하기</th>
              <th style={{width:'15%'}}>사이트<br/>보기</th>
              <th style={{width:'10%'}}>완료<br/>여부</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? ( // 로딩 중일 때 메시지 표시
              <tr>
                <td colSpan="5">잠시만 기다려주세요...</td>
              </tr>
            ) :
            filteredSites.length > 0 ? (
              filteredSites.map((site, index) => (
                <tr key={index}>
                  <td>{site.agency}</td>
                  <td>{site.name}</td>
                  <td>
                    <button
                      className={`selectform-button ${
                        site.complete === 1
                        ? 'survey-complete' // 완료 시 스타일
                        : surveyInProgress[index]
                        ? 'survey-in-progress' // 진행 중일 때 스타일
                        : ''
                      }`}
                      onClick={() => handleFormSelection(site.form, index)}
                      disabled={site.complete === 1} // 설문 완료 시 버튼 비활성화
                    >
                      {site.complete === 1
                        ? '설문완료' // 완료 시 텍스트
                        : surveyInProgress[index]
                        ? '설문중' // 진행 중일 때 텍스트
                        : '설문'}
                    </button>
                  </td>
                  <td>
                    <button
                      className="selectUrl-button"
                      onClick={() =>
                        window.open(site.url, '_blank', 'noopener,noreferrer')}
                    >
                      이동
                    </button>
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={site.complete === 1}
                      onChange={() =>
                        handleCheckboxChange(site.key, site.complete)
                      }
                    />
                  </td>
                </tr>
              ))
            ) : (
              isQueryAttempted && ( // 조회가 시도되었을 때만 메시지를 표시
                <tr>
                  <td colSpan="5">정보가 없습니다.</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Left;
